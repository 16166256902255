import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { down } from '@thomaseckhardt/styled-breakpoints'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'
import Article, { VARIANT_TEXT_INTENDED } from '../modules/Article'
import { VARIANT_FOURTHIRD_DUO_QUADRAT_INTENDED, VARIANT_PORTRAIT_DUO_INTENDED } from '../components2/Puzzle'
import JumpNav from '../components2/JumpNav'
import Teaser from '../modules/Teaser'

const Main = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 1fr;

  > * {
    grid-column: 1 / span 16;
    min-height: 70vh;
  }

  > *:nth-child(2) {
    grid-row: 1;
    min-height: 0;
  }
`

const MainContent = styled.article`
  grid-column: 1 / span 16;
  min-height: 70vh;
`

const JumpNavContainer = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: 1 / span 3;
  z-index: 1;

  ${down('laptop')} {
    display: none;
  }
`

const StyledJumpNav = styled(JumpNav)`
  position: sticky;
  top: 0;
  padding-top: ${props => props.theme.px('space.11')};
`

const Page = ({ data }) => {
  const postNode = {
    title: `Polstern - ${config.siteTitle}`,
  }

  const blockIntro = {
    leading: 10,
    variant: VARIANT_TEXT_INTENDED,
    id: `sicht-sonnenschutz`,
    headline: `Sicht und Sonnenschutz`,
    lead: `In der Sommerzeit ist es warm und lange hell, im Winter kalt und früh dunkel. Doch manchmal ist es zu viel oder zu wenig Licht. Die Sonne in der Wohnung schafft Wohlempfinden und hebt die Stimmung, Um dies zu steuern richten wir Ihre Wohnung entsprechend ein. Wir kombinieren Vorhänge und innenliegenden Sonnenschutz zu passenden Raumdekorationen um Ihre Wünsche rund um die Sonne und das Licht zu erfüllen.`,
  }

  const blockCurtain1 = {
    leading: 10,
    variant: VARIANT_TEXT_INTENDED,
    id: `gardinen-und-dekorationen`,
    headline: `Gardinen und Dekorationen`,
    copytext: `Wir verwirklichen Ihre Wünsche für schöne Dekorationen. Ob stilgebend, funktional und dekorativ – Vorhänge und Dekorationen fertigen wir für jeden Einsatzbereich an, genau nach Ihren Wünschen und Vorstellungen. Durch unser handwerkliches Können als Meisterbetrieb sind der Kreativität kaum Grenzen gesetzt. Wenn Sie einen Stoff aus Leinen, Seide oder Baumwolle wünschen oder sie benötigen eine Lösung zur Verbesserung des Raumhalls, Schallschutzes oder eine funktionale Lösung für einen wärmedämmenden Vorhang, dann sprechen Sie uns gerne an.`,
    puzzle: {
      caption: `Edle Stoffe von ZIMMER + ROHDE`,
      variant: VARIANT_FOURTHIRD_DUO_QUADRAT_INTENDED,
      items: [
        {
          fluid: data.curtainFourthirdPortrait.childImageSharp.fluid
        },
        {
          fluid: data.curtainQuadrat.childImageSharp.fluid
        },
        {
          fluid: data.curtainFourthirdLandscape.childImageSharp.fluid
        }
      ]
    }
  }

  const blockCurtain2 = {
    leading: 3,
    variant: VARIANT_TEXT_INTENDED,
    copytext: `
      <p>Durch die langjährige Zusammenarbeit mit Herstellern wie JAB ANSTOETZ, Dedar, Rubelli, création baumann, Pierre Frey, ZIMMER + RHODE, ADO, Intex  uvm. entwickeln und gestalten wir mit Ihnen zusammen Ihren Dekorationswunsch.</p>
    `
  }

  const blockSunShading1 = {
    leading: 10,
    variant: VARIANT_TEXT_INTENDED,
    id: `sonnenschutz`,
    headline: `Sonnenschutz`,
    copytext: `
      <p>Nicht selten beginnt die Wahl des passenden Sonnenschutzes mit der Überlegung: innen oder außen?</p>
      <p>Gegenüber Lösungen an der Gebäudeaußenhülle hat innen liegender Sonnenschutz viele Vorteile: Wind, Wetter und jahreszeitliche Temperaturschwankungen können ihm kaum etwas anhaben. Innenliegende Systeme sind leicht zugänglich, um gereinigt, individuell verstellt oder ausgetauscht zu werden. Von außen kaum oder gar nicht wahrnehmbar, ist der innenseitige Sonnenschutz zudem oft die einzige Verschattungsmöglichkeit bei denkmalgeschützten Fassaden.</p>
    `,
    puzzle: {
      variant: VARIANT_PORTRAIT_DUO_INTENDED,
      caption: `Innovative Technologien mit dem Sonnenschutz von erfal`,
      items: [
        {
          fluid: data.sunShading1.childImageSharp.fluid
        },
        {
          fluid: data.sunShading2.childImageSharp.fluid
        }
      ]
    }
  }

  const blockSunShading2 = {
    leading: 3,
    variant: VARIANT_TEXT_INTENDED,
    copytext: `
      <p>Ob Jalousien, Rollos, Vorhänge, Lamellen und Faltstore (Plissee-Anlagen) vertikal oder schräg vor Wand- oder Dachfenstern angebracht. Für Ihre Anforderungen bieten wir das passende System.</p>
      <p>Hier können Sie sich auf unsere Erfahrung verlassen! Wir beraten Sie, welches System Ihren Wünschen am besten entspricht.</p>
      <p>Natürlich haben wir auch den im Außenbereich liegenden Sonnenschutz in unserem Angebot.</p>
      <p>In dem Bereich Markisen und Außenjalousien arbeiten wir mit namhaften Herstellern, wie z. B. Büscher, Nova Hüppe seit Jahren erfolgreich zusammen. Und wir liefern nicht nur neue Anlagen. Auch die Reparatur oder der Tausch eines Markisentuchs gehört zu unserem Angebot.</p>
    `
  }

  const jumpNavItems = [
    {
      label: blockIntro.headline,
      href: `#${blockIntro.id}`,
      active: true
    },
    {
      label: blockCurtain1.headline,
      href: `#${blockCurtain1.id}`,
    },
    {
      label: blockSunShading1.headline,
      href: `#${blockSunShading1.id}`,
    },
  ]

  const serviceTeaser = {
    leading: 10,
    topline: `Unser Kundenservice`,
    headline: `Ausmessen, Nähen und Dekorieren Ihrer Gardinen`,
    action: {
      to: `/kundenservice#sichtsonnenschutz`
    },
    image: {
      objectPosition: `100% 100%`,
      fluid: data.serviceTeaser.childImageSharp.fluid
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Sicht- und Sonnenschutz - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="sich-sonnenschutz" customTitle />

      <Main>
        <JumpNavContainer>
          <StyledJumpNav items={jumpNavItems} />
        </JumpNavContainer>
        <MainContent>
          <Article as="header" {...blockIntro}></Article>
          <Article as="article" {...blockCurtain1}></Article>
          <Article as="article" {...blockCurtain2}></Article>
          <Article as="article" {...blockSunShading1}></Article>
          <Article as="article" {...blockSunShading2}></Article>
        </MainContent>
      </Main>
      <Teaser
        as="aside"
        {...serviceTeaser} />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    serviceTeaser: file(relativePath: { eq: "service-gardinen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    curtainQuadrat: file(relativePath: { eq: "gardinen_stoffe_zimmerrohde-quadrat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    curtainFourthirdLandscape: file(relativePath: { eq: "gardinen_stoffe-zimmerrohde-fourthird-landscape.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    curtainFourthirdPortrait: file(relativePath: { eq: "gardinen_stoffe-zimmerrohde-fourthird-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sunShading1: file(relativePath: { eq: "sonnenschutz-erfal_jalousien.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sunShading2: file(relativePath: { eq: "sonnenschutz-erfal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`


